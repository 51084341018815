import React, { Suspense } from 'react'

const Page = React.lazy(() => import('./components/Page/'));

function GameLoader() {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Page />
        </Suspense>
    );
}

export default GameLoader
