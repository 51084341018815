import { shuffle } from 'utils/arrays'

const cards = [
    {
        id: 1,
        slug: 'prince-felix',
        team: 'conspirator',
        name: 'Prince Felix',
        type: 'character',
        avatar: '/images/characters/PrinceFelix.png',
        card: '/images/characters/PrinceFelix.png',
        ability: 'Conspire',
        fullname: 'Felix Yusupov',
        weapon: 'Revolver',
        description: 'Prince Felix Felixovich Yusupov, Count Sumarokov-Elston was a Russian aristocrat, prince and count from the Yusupov family. He is best known for participating in the assassination of Grigori Rasputin and marrying the niece of Tsar Nicholas II.',
        ability_desc: 'Initiate a conspiract with other players to murder someone.'
    },
    {
        id: 2,
        slug: 'grand-duke',
        team: 'conspirator',
        name: 'Grand Duke',
        type: 'character',
        avatar: '/images/characters/GrandDuke.png',
        card: '/images/characters/GrandDuke.png',
        ability: 'Reveal',
        fullname: 'Dmitri Pavlovich',
        weapon: 'Rope',
        description: 'Grand Duke Dimitri Pavlovich of Russia was a Russian Grand Duke and one of the few Romanovs to escape murder by the Bolsheviks after the Russian Revolution.',
        ability_desc: 'Look at another person’s secret identity.'
    },
    {
        id: 3,
        slug: 'empress',
        team: 'royal',
        name: 'Empress',
        type: 'character',
        avatar: '/images/characters/Empress.png',
        card: '/images/characters/Empress.png',
        ability: 'Blackmail',
        fullname: 'Alexandra Feodorovna',
        weapon: 'Dagger',
        description: 'Alexandra Feodorovna was Empress of Russia as the spouse of Nicholas II—the last ruler of the Russian Empire—from their marriage on 26 November 1894 until his forced abdication on 15 March 1917.',
        ability_desc: 'Make two players swap death or action cards.'
    },
    {
        id: 4,
        slug: 'doctor',
        team: 'conspirator',
        name: 'Doctor',
        type: 'character',
        avatar: '/images/characters/Doctor.png',
        card: '/images/characters/Doctor.png',
        ability: 'Placebo',
        fullname: 'Stanislaus de Lazovert',
        weapon: 'Poison',
        description: 'Stanislaus de Lazovert was born in Poland. He moved to Russia and during the First World War he met Vladimir Purishkevich while he was in charge of a medical aid train on the Eastern Front. In 1916, Purishkevich, the leader of the monarchists in the Duma, recruited Lazovert, to muder Grigory Rasputin.',
        ability_desc: 'May discard a different card than the one used this turn. Power cannot be used on the same card twice.'
    },
    {
        id: 5,
        slug: 'tsar',
        team: 'royal',
        name: 'Tsar',
        type: 'character',
        avatar: '/images/characters/Tsar.png',
        card: '/images/characters/Tsar.png',
        ability: 'Reveal',
        fullname: 'Nicholas II',
        weapon: 'Razor',
        description: 'Nicholas II or Nikolai II, known as Saint Nicholas the Passion-Bearer in the Russian Orthodox Church, was the last Emperor of Russia, ruling from 1 November 1894 until his forced abdication on 15 March 1917.',
        ability_desc: 'Look at another person’s secret identity.'
    },
    {
        id: 6,
        slug: 'politician',
        team: 'conspirator',
        name: 'Politician',
        type: 'character',
        avatar: '/images/characters/Politician.png',
        card: '/images/characters/Politician.png',
        ability: 'Coverup',
        fullname: 'Vladimir Purishkevich',
        weapon: 'Knife',
        description: 'Vladimir Mitrofanovich Purishkevich was a right-wing politician in Imperial Russia, noted for his monarchist, ultra-nationalist, antisemitic and anticommunist views. Because of his restless behaviour, he was regarded as a loose cannon.',
        ability_desc: 'Choose a player’s weapon or action card and secretly decide to trade it.'
    },
    {
        id: 7,
        slug: 'heir',
        team: 'royal',
        name: 'Heir Apparent',
        type: 'character',
        avatar: '/images/characters/Heir.png',
        card: '/images/characters/Heir.png',
        ability: 'Bedridden',
        fullname: 'Alexei Nikolaevich',
        weapon: 'Cane',
        description: 'Alexei Nikolaevich of the House of Romanov, was the Tsesarevich and heir apparent to the throne of the Russian Empire. He was the youngest child and only son of Emperor Nicholas II and Empress Alexandra Feodorovna.',
        ability_desc: 'May use the secrets skill, but cannot use the selected card that turn.'
    },
    {
        id: 8,
        slug: 'lieutenant',
        team: 'conspirator',
        name: 'Lieutenant',
        type: 'character',
        avatar: '/images/characters/Lieutenant.png',
        card: '/images/characters/Lieutenant.png',
        ability: 'Double Time',
        fullname: 'Sergei Mikhailovich Sukhotin',
        weapon: 'Rifle',
        description: 'A close friend of the then Tsarevich Nicholas. They grew apart upon Nicholas II\'s marriage and accession to the throne.',
        ability_desc: 'May perform the same action twice in a turn, excluding Conspire!'
    },
    {
        id: 9,
        slug: 'grand-duchess',
        team: 'royal',
        name: 'Grand Duchess',
        type: 'character',
        avatar: '/images/characters/GrandDuchess.png',
        card: '/images/characters/GrandDuchess.png',
        ability: 'Coverup',
        fullname: 'Anastasia Nikolaevna',
        weapon: 'Scissors',
        description: 'Grand Duchess Anastasia Nikolaevna of Russia was the youngest daughter of Tsar Nicholas II, the last sovereign of Imperial Russia, and his wife, Tsarina Alexandra Feodorovna',
        ability_desc: 'Choose a player’s weapon or action card and secretly decide to trade it.'
    },
    {
        id: 10,
        slug: 'spy',
        team: 'conspirator',
        name: 'British Spy',
        type: 'character',
        avatar: '/images/characters/Spy.png',
        card: '/images/characters/Spy.png',
        ability: 'Improvise', // was Secrets
        fullname: 'Oswald Rayner',
        weapon: 'Knife',
        description: 'Oswald Rayner was a British MI6 agent in Russia during World War I. He is believed by some to have been involved in the final murder plot against Grigori Rasputin.',
        ability_desc: 'May look at the top 4 cards in the discard pile, and choose one to use.'
    },
    {
        id: 11,
        slug: 'royal-guard',
        team: 'royal',
        name: 'Royal Guard',
        type: 'character',
        avatar: '/images/characters/RoyalGuard.png',
        card: '/images/characters/RoyalGuard.png',
        ability: 'Marching Orders',
        fullname: '',
        weapon: 'Saber',
        description: 'A member of the Royal Guard sworn to protect the Royal family with their life. He has a very tall hat.',
        ability_desc: 'May perform an action that the previous player performed.'
    },
    {
        id: 12,
        slug: 'butler',
        team: 'none',
        name: 'Butler',
        type: 'character',
        avatar: '/images/characters/Butler.png',
        card: '/images/characters/Butler.png',
        ability: 'Blackmail',
        fullname: 'Buzhinsky',
        weapon: 'Dumbbell',
        description: 'Servant to the Yusupov family. Not much is known of him, but then not much is ever remembered about the "help".',
        ability_desc: 'Use to prevent a player from taking any action besides Conspire!or using a Doppelgänger in defence.'
    },
    {
        id: 13,
        slug: 'constable',
        team: 'none',
        name: 'Constable',
        type: 'character',
        avatar: '/images/characters/Constable.png',
        card: '/images/characters/Constable.png',
        ability: 'Investigate',
        fullname: 'Stepan Vlusyuk',
        weapon: 'Club',
        description: 'The forty-eight-year-old constable, Stepan Vlasyuk, who was on duty not far from the Yusupov palace.',
        ability_desc: 'Can view the death or action card of a player.'
    },
    {
        id: 14,
        slug: 'groundskeeper',
        team: 'none',
        name: 'Groundskeeper',
        type: 'character',
        avatar: '/images/characters/Groundskeeper.png',
        card: '/images/characters/Groundskeeper.png',
        ability: 'Secrets',
        fullname: 'Dmitri Nekhlyudov',
        weapon: 'Axe',
        description: 'A grisled veteran and gardener to the surrounding palace grounds. He\'s a chronic mumbler in his sleep.',
        ability_desc: 'When everyone has to close their eyes the groundskeeper can peak.'
    },
    {
        id: 15,
        slug: 'watchmaker',
        team: 'none',
        name: 'Watchmaker',
        type: 'character',
        avatar: '/images/characters/Watchmaker.png',
        card: '/images/characters/Watchmaker.png',
        ability: 'Revolution!',
        fullname: 'Yakov Yurovsky',
        weapon: 'Molotov',
        description: 'The Bolsheviks, led by Yakov Yurovsky, a rigid black-bearded man who worked in the local Cheka (secret police), acted in cold blood, finishing off those who didn’t die right away with knives and bayonets.',
        ability_desc: ''
    }
]

export const getDeck = (players) => {
    return shuffle(cards.slice(0, players))
}

export default cards
