import firebase from 'firebase'

const config = {
    apiKey: 'AIzaSyBiqxgyg2vA3bmHrY0MWekZyNN3OFkt_JM',
    authDomain: 'conspiracy-game.firebaseapp.com',
    databaseURL: 'https://conspiracy-game.firebaseio.com',
    projectId: 'conspiracy-game',
    storageBucket: 'conspiracy-game.appspot.com',
    messagingSenderId: '4811369362'
}

export const app = firebase.initializeApp(config)
export const database = firebase.database()

export default database
