import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import characters from 'models/characters'
import './Page.scss'

export const Page = ({ match: { params: { slug } } }) => {

    const character = slug && characters.find(c => c.slug === slug)

    return <div className='page-content'>

        {character && <div className='character-info'>

            <img src={character.card} width='300' className='image' alt='' />

            <div className='character-meta'>
                <h2>{character.name}</h2>
                <h3>{character.fullname}</h3>
                <p>{character.description || 'Not much is known about them.'}</p>
            </div>

            {character.lore && <div>
                <h3>Lore</h3>
                <p>{character.lore}</p>
            </div>}


            <p><Link to='/lore' className='btn btn-secondary'>View All Characters</Link></p>

        </div>}

    </div>
}

export default withRouter(Page)
