export const AGREE_TERMS = 'agree_terms'
export const PLAYER_COUNT = 'player_count'
export const PLAYER_ID = 'player_id'
export const NOTE_STORAGE_KEY = 'notes'

export const AGREE_HASH = 'ed498560cfdd6c8fc20231035c8e52a1'

export const setLocalStorage = (key, values) => {
    const data = JSON.stringify(values)
    return sessionStorage.setItem(key, data) || localStorage.setItem(key, data)
}

export const getLocalStorage = (key) => {
    let local = localStorage.getItem(key)
    let session = sessionStorage.getItem(key)
    return JSON.parse(session) || JSON.parse(local)
}
