// We only need to import the modules necessary for initial render
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import PageLayout from '../layouts/PageLayout/PageLayout'
import HomeRoute from './Home'
import RulesRoute from './Rules'
import NotesRoute from './Notes'
import CastRoute from './Cast'
import CharacterRoute from './Character'
import GameRoute from './Game'

export const Router = () => <BrowserRouter>
    <PageLayout>
        <Switch>
            <Route exact path='/' component={HomeRoute} />

            <Route exact path='/rules' component={RulesRoute} />
            <Route exact path='/notes/:section?' component={NotesRoute} />
            <Route exact path='/lore' component={CastRoute} />
            <Route exact path='/character/:slug?' component={CharacterRoute} />

            <Route exact path='/play' component={GameRoute} />
        </Switch>
    </PageLayout>
</BrowserRouter>

export default Router
