import React, { Component } from 'react'
import { BrowserRouter } from 'react-router-dom'
import firebase from 'firebase'
import injectTapEventPlugin from 'react-tap-event-plugin'
import { Provider } from 'react-redux'
import PropTypes from 'prop-types'

import Routes from 'routes'
import database from '../database'
import { sessionChange, userChange } from '../store/session'
import { joinRoom } from '../database/actions/rooms'

export default class App extends Component {
    static propTypes = {
        store: PropTypes.object.isRequired,
        routes: PropTypes.object.isRequired,
    }

    shouldComponentUpdate () {
        return false
    }

    componentWillMount () {
        if (typeof window !== 'undefined') {
            try { injectTapEventPlugin() } catch (err) {
                // do nothing
            }
        }
    }

    componentDidMount () {
        const { store } = this.props

        if (firebase) {
            firebase.auth().onAuthStateChanged((session) => {
                if (session && session.uid) {
                    store.dispatch(sessionChange(session))

                    database.ref(`users/${session.uid}`).on('value', (resp) => {
                        const user = resp.val()
                        if (user) {
                            store.dispatch(userChange(user))

                            if (user.roomID) {
                                store.dispatch(joinRoom(user.roomID, user, true))
                            }
                        }
                    })

                    database.ref(`/users/${session.uid}`).update({
                        id: session.uid,
                        last_login: (new Date()).getTime()
                    })
                }
            })

            firebase.auth().signInAnonymously()
        }
    }

    render () {
        return <Provider store={this.props.store}>
            <BrowserRouter>
                <Routes />
            </BrowserRouter>
        </Provider>
    }
}

