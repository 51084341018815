import database from '../index'
import { roomChange, chatChange, gameChange, notesChange } from 'store/session'
import { shuffle } from 'utils/arrays'
import { customAlphabet } from 'nanoid'
import weapons from '../../models/weapons'

const ROOM_CHARACTERS = '123456789ABCDEFGHIJKLMNPQRSTUVWXYZ'
const generateRoomID = customAlphabet(ROOM_CHARACTERS, 6)
const PASS_DELAY = 3000
const READ_DELAY = 7000

const listenToRoom = (roomID, playerID, dispatch) => {
    database.ref(`rooms/${roomID}`).on('value', (room) => {
        dispatch(roomChange(room.val()))
    })

    database.ref(`chat/${roomID}/messages`).on('value', (chat) => {
        dispatch(chatChange(chat)) // Keep using order from db
    })

    database.ref(`games/${roomID}`).on('value', resp => {
        dispatch(gameChange(resp.val()))
    })

    database.ref(`knowledge/${roomID}/${playerID}`).on('value', resp => {
        dispatch(notesChange(resp.val()))
    })
}

export const createRoom = (user) => {
    const roomID = generateRoomID()

    if (user && user.id) {
        const room = {
            id: roomID,
            owner: user.id,
            host: user.id,
            private: true,
            people: { [user.id]: user },
            isClosed: false
        }

        return (dispatch, getState) => {
            listenToRoom(roomID, user.id, dispatch)

            database.ref(`rooms/${roomID}`).set(room).then(() => {
                database.ref(`users/${user.id}/roomID`).set(roomID)
                dispatch(roomChange(room))
            })
        }
    }
}

export const joinRoom = (roomID, user, ignoreLogin = false) => {
    return (dispatch, getState) => {

        database.ref(`rooms/${roomID}`).once('value').then((snap) => {
            const room = snap.val()

            if (room) {
                database.ref(`rooms/${roomID}/people/${user.id}`).set({ ...user, isSpectator: room.isClosed })
                if (!user.isBot) database.ref(`users/${user.id}/roomID`).set(roomID)
                listenToRoom(roomID, user.id, dispatch)

                if (!ignoreLogin) {
                    dispatch(postMessage(roomID, {
                        roomID: roomID,
                        userID: user.id,
                        name: 'T-Bot',
                        message: user.name + ' joined the room!',
                        type: 'user-add',
                        isBot: true,
                        read: true
                    }))
                }
            } else {
                database.ref(`users/${user.id}/roomID`).remove()
                alert('That room does not exist')
            }
        })

    }
}

export const updateRoom = (roomID, room) => {
    return (dispatch, getState) => {
        return database.ref(`rooms/${roomID}`).update(room)
    }
}

export const leaveRoom = (roomID, userID, useBot) => {
    return (dispatch, getState) => {
        if (useBot) {
            database.ref(`rooms/${roomID}/people/${userID}`).update({ isBot: true })
        } else {
            database.ref(`rooms/${roomID}/people/${userID}`).remove()
        }

        database.ref(`users/${userID}/roomID`).remove()
    }
}

export const closeRoom = (roomID) => {
    return (dispatch, getState) => {

        const updateUsers = {}
        database.ref(`rooms/${roomID}`).once('value').then((snap) => {
            snap.forEach(node => {
                const { id } = node.val()
                updateUsers[`${id}/roomID`] = null
            })

            database.ref(`users`).update(updateUsers)
            database.ref(`rooms/${roomID}`).remove()
        })
    }
}

export const postMessage = (roomID, message) => {
    return (dispatch, getState) => {
        return database.ref(`chat/${roomID}/messages`).push({ ...message, timestamp: (new Date()).getTime() })
    }
}

export const updateGame = (gameID, updates) => {
    return (dispatch, getState) => {
        console.warn('updategame', updates)
        return database.ref(`games/${gameID}`).update(updates)
    }
}

export const createGame = (gameID, game) => {
    return (dispatch, getState) => {
        database.ref(`games/${gameID}`).set(game).then(resp => {
            dispatch(gameChange(game))
        })

        // Clear out any old info, each person should know the weapon they start with
        const knowledge = { }
        for (let i = 0; i < game.players.length; i++) {
            let person = game.players[i]
            knowledge[person.id] = { people: { [person.id]: { weapon: {0: person.weapon} } } }
        }
        database.ref(`knowledge/${gameID}`).set(knowledge)
    }
}

// Game actions

export const nextPlayer = (game) => {
    return (dispatch, getState) => {

        if (game.nextTurn) {
            // After this is done, check if game over or move to next Alive player
            const updates = { nextTurn: false }
            const gameover = isGameOver(game)

            if (gameover) {
                updates['gameover'] = gameover
            } else {
                const nextPlayer = nextActivePlayer(game)
                updates['activePlayer'] = nextPlayer
                updates[`players/${nextPlayer}/isTargetable`] = true
            }

            console.warn('nextplayer', updates)
            database.ref(`games/${game.roomID}`).update(updates)
        }
    }
}

const topCard = (game) => {
    const card = game.actionDeck.pop()

    if (!game.actionDeck.length) {
        game.actionDeck = shuffle(game.discardDeck)
        game.discardDeck = []
    }

    return card
}

export const drawCards = (game, player, fillTo = 2, save = true) => {
    return (dispatch, getState) => {

        if (!player) {
            console.warn('No active player', player)
        }

        let { actionDeck, discardDeck = [] } = game
        while (player.action.length < fillTo) {
            if (!actionDeck.length) {
                actionDeck = shuffle(discardDeck)
                discardDeck = []
            }

            player.action.push(actionDeck.pop())
        }

        const updates = {
            'actionDeck': actionDeck,
            'discardDeck': discardDeck,
            [`players/${player.index}/action`]: player.action
        }

        return save ? database.ref(`games/${game.roomID}`).update(updates) : updates
    }
}

export const discardCard = (game, playerIndex, cardID, save = true) => {
    return (dispatch, getState) => {
        const player = game.players[playerIndex]
        const cardIndex = player.action.findIndex(c => c.id === cardID)
        const card = player.action.splice(cardIndex, 1)

        if (!game.discardDeck) game.discardDeck = []
        if (card) { game.discardDeck = game.discardDeck.concat(card) }

        // Need at least 1 card in hand
        if (!player.action.length) {
            player.action.push(topCard(game))
        }

        const updates = {
            'discardDeck': game.discardDeck,
            [`players/${playerIndex}/action`]: player.action
        }


        console.warn('discardcard', updates)
        return save ? database.ref(`games/${game.roomID}`).update(updates) : updates
    }
}


// Player actions
export const getOptions = (player, action, game) => {
    const options = {}
    const allButYou = game.players.filter(p => p.id !== player.id)

    if (action.cardIndex < 0) {
        options.discard = player.action // If not from a card, player must choose what to discard
    }

    switch (action.name) {
        case 'Conspire':
        case 'Angel of Death':
            options.targetGroup1 = allButYou
            options.weapons = weapons.slice(0, game.players.length)
            break

        case 'Investigate':
        case 'Coverup':
            options.targetGroup1 = allButYou
            options.cardTypes = ['Death', 'Action']
            break

        case 'Soul Swap':
        case 'Reveal':
            options.targetGroup1 = allButYou
            break

        case 'Blackmail':
            options.targetGroup1 = allButYou
            options.targetGroup2 = allButYou
            options.cardTypes = ['Death', 'Action']
            break

        case 'Secrets':
        case 'Bedridden':
        case 'Quick Draw':
            options.cards = Array.isArray(game.discardDeck) ? game.discardDeck.slice(-4) : []
            break

        case 'Pass':
        case 'Discard':
            options.discard = player.action

        case 'Doppleganger':
        default:
        // do nothing
    }

    return options
}

const actionToString = (game, player, action, options) => {
    const target1 = game.players[+options.target1]
    const target2 = game.players[+options.target2]

    // Need to mask name for secrecy
    let actionName = action.name
    if (actionName === 'Coverup2') actionName = 'Coverup'
    if (actionName === 'Secrets2') actionName = 'Secrets'
    if (actionName === 'Bedridden') actionName = 'Secrets'
    if (actionName === 'Quick Draw') actionName = 'Secrets'

    let string = player.name + ' is using ' + actionName

    if (target1 || target2) {
        string += ' on ' + target1.name + (target2 ? ' and ' + target2.name : '')
    }

    return string
}

const awaitingPlayers = (game, player, action, options) => {
    let awaiting = {}
    let filtered = []

    let target1 = game.players[options.target1]
    let target2 = game.players[options.target2]

    // targets refer to the players index
    if (action.name === 'Conspire') {
        // All but the Player and the target
        filtered = game.players.filter(p => (p.index !== player.index && p.index !== target1.index))
        awaiting = filtered.reduce((awaits, player) => {
            awaits[player.index] = false
            return awaits
        }, {})
    } else if (['Investigate', 'Coverup', 'Reveal'].includes(action.name)) {
        awaiting = { [target1.index]: false }
    } else if (action.name === 'Blackmail') {
        awaiting = { [target1.index]: false, [target2.index]: false }
    } else if (action.name === 'Coverup2' || action.name === 'Secrets2') {
        awaiting = { [player.index]: false }
    }

    return awaiting
}

export const nextActivePlayer = (game) => {
    const currentPlayer = game.activePlayer
    let next = (game.activePlayer + 1) % game.players.length

    while (!game.players[next].isAlive && currentPlayer !== next) {
        next = (next + 1) % game.players.length
    }

    return next
}

export const setPendingAction = (game, player, action, options, post) => {
    return (dispatch, getState) => {
        const message = actionToString(game, player, action, options)

        if (post) {
            dispatch(postMessage(game.roomID, {
                roomID: game.roomID,
                userID: player.id,
                name: player.name,
                message: actionToString(game, player, action, options),
                type: action,
                isBot: true,
            }))
        }

        const awaiting = awaitingPlayers(game, player, action, options)
        const pending = { action, playerID: player.id, playerIndex: player.index, options, string: message, awaiting }
        game.pending = pending

        let updates = {
            pending
        }

        // If no one is required for action, follow through in enough time for people to read.
        console.warn('pending action', updates)
        return database.ref(`games/${game.roomID}`).update(updates).then(() => {
            if (!Object.keys(awaiting).length) {
                setTimeout(() => {
                    dispatch(resolveAction(game)) // Go ahead and give people enough time to see what will happen
                }, action.name === 'Pass' ? PASS_DELAY : READ_DELAY)
            }
        })
    }
}

const stillWaiting = (awaiting) => {
    const awaitingIDs = Object.keys(awaiting)
    return awaitingIDs.reduce((total, id) => (+total + (awaiting[id] === false)), 0)
}

export const counterAction = (game, player, counter) => {
    return (dispatch, getState) => {
        const { pending } = game
        const updates = {}

        pending.awaiting[player.index] = counter
        updates[`pending/awaiting/${player.index}`] = counter

        if (counter !== 'Pass' && pending.action.name !== 'Conspire') {
            // Report to room chat
        }

        // After saving check to see if everyone has performed their action
        console.warn('counter', updates)
        return database.ref(`games/${game.roomID}`).update(updates).then(
            () => {
                if (!stillWaiting(pending.awaiting)) {
                    dispatch(resolveAction(game))
                }
            }
        )
    }
}

export const conspire = (game, updates, dispatch, instakill = false) => {
    const { pending, players, moika } = game
    const alive = players.filter(p => p.isAlive)
    const { playerIndex, action, options, awaiting } = pending
    const weapon = options.weapon

    const player = game.players[playerIndex]
    const target1 = game.players[+options.target1]
    const awaitingIDs = Object.keys(awaiting)

    const totalVotes = awaitingIDs.reduce((votes, id) => (votes + (awaiting[id] === 'Conspire' ? 1 : 0)), 0)

    if (instakill || totalVotes > 1 || alive.length === 2 || (alive.length < 7 && totalVotes)) {

        if (!instakill) {
            updates[`players/${player.index}/token`] = false // Token is one time use on Conspire action
        }

        if (target1.action[0].name === 'Doppelganger') {

            let moreUpdates = discardCard(game, target1.index, target1.action[0].id, false)
            updates = { ...updates, ...moreUpdates }

            dispatch(postMessage(game.roomID, {
                roomID: game.roomID,
                userID: player.id,
                name: player.name,
                message: `${target1.name} was saved by their Doppelganger!`,
                type: action,
                isBot: true,
                read: true
            }))

        } else if (target1.action[0].name === 'Angel of Death') {

            let moreUpdates = discardCard(game, target1.index, target1.action[0].id, false)
            updates = { ...updates, ...moreUpdates }

            dispatch(postMessage(game.roomID, {
                roomID: game.roomID,
                userID: player.id,
                name: player.name,
                message: `${target1.name} was saved by using Angel of Death!`,
                type: action,
                isBot: true,
                read: true
            }))

        } else if (target1.action[0].name === 'Soul Swap') {

            let moreUpdates = discardCard(game, target1.index, target1.action[0].id, false)
            updates = { ...updates, ...moreUpdates }

            dispatch(postMessage(game.roomID, {
                roomID: game.roomID,
                userID: player.id,
                name: player.name,
                message: `${target1.name} was saved by using Soul Swap!`,
                type: action,
                isBot: true,
                read: true
            }))

        } else if (weapon === target1.weapon.id) {

            updates[`players/${player.index}/isAlive`] = false // Backstab

            dispatch(postMessage(game.roomID, {
                roomID: game.roomID,
                userID: player.id,
                name: player.name,
                message: `${player.name} used the victims weapon and was backstabbed!`,
                type: action,
                isBot: true,
                read: true
            }))

        } else {

            updates[`players/${target1.index}/isAlive`] = false // Dead now see if Rasputin

            dispatch(postMessage(game.roomID, {
                roomID: game.roomID,
                userID: player.id,
                name: player.name,
                message: `${target1.name} was murdered by a gang of ${totalVotes + 1}`,
                type: action,
                isBot: true,
                read: true
            }))

            if (target1.weapon.name === 'Rasputin') {
                if (moika.id === +weapon) {
                    updates['gameover'] = 'conspirator' // Conspirators win

                    dispatch(postMessage(game.roomID, {
                        roomID: game.roomID,
                        userID: player.id,
                        name: player.name,
                        message: `${target1.name} was possessed by Rasputin... and Rasputin was successfully stopped!`,
                        type: action,
                        isBot: true,
                        read: true
                    }))

                } else if (alive.length === 2) {

                    updates['gameover'] = 'rasputin'

                    dispatch(postMessage(game.roomID, {
                        roomID: game.roomID,
                        userID: player.id,
                        name: player.name,
                        message: `${target1.name} was possessed by Rasputin... Rasputin possessed the remaining player!`,
                        type: action,
                        isBot: true,
                        read: true
                    }))

                } else {
                    // Will be random to start
                    let possessions = players.filter(p => p.isAlive && p.id !== target1.id)
                    let chosen1 = possessions[Math.floor(Math.random() * possessions.length)]

                    updates[`players/${chosen1.index}/weapon`] = target1.weapon
                    updates[`players/${target1.index}/weapon`] = chosen1.weapon

                    dispatch(postMessage(game.roomID, {
                        roomID: game.roomID,
                        userID: player.id,
                        name: player.name,
                        message: `${target1.name} was possessed by Rasputin... Rasputin possessed another player!`,
                        type: action,
                        isBot: true,
                        read: true
                    }))
                }
            }
        }

    } else {
        // The conspiracy failed
        dispatch(postMessage(game.roomID, {
            roomID: game.roomID,
            userID: player.id,
            name: player.name,
            message: 'The conspiracy failed!',
            type: action,
            isBot: true,
            read: true
        }))
    }

    return updates
}

export const isGameOver = (game) => {
    if (game.gameover) return true

    const { players } = game
    const alive = players.filter(p => p.isAlive)
    const teamC = alive.filter(p => (p.character.team === 'conspirator' && p.weapon.name !== 'Rasputin'))
    const teamR = alive.filter(p => (p.character.team === 'royal' || p.weapon.name === 'Rasputin'))

    if (alive.length === 1) return 'rasputin'
    if (teamC.length === 0) return 'royal'
    if (teamR.length === 0) return 'conspirator'

    return false
}

export const resolveAction = (game) => {

    return (dispatch, getState) => {

        let updates = {}
        const { players, pending, moika } = game
        const { playerID, playerIndex, action, options, awaiting } = pending

        const player = game.players[playerIndex]
        const target1 = game.players[+options.target1]
        const target2 = game.players[+options.target2]
        const weapon = options.weapon
        const card = options.card
        const discardID = options.discard || action.cardID

        let type, isComplete = false

        switch (action.name) {
            case 'Conspire':
                updates = conspire(game, updates, dispatch)
                isComplete = true
                break

            case 'Angel of Death':
                updates = conspire(game, updates, dispatch, true)
                isComplete = true
                break

            case 'Investigate':
                type = options.type
                isComplete = true
                break

            case 'Coverup':
                type = options.type
                options.read = false
                if (type === 'Death') {
                    options.cards = [player.weapon, target1.weapon]
                } else {
                    options.cards = player.action.concat(target1.action)
                }

                dispatch(setPendingAction(game, player, { name: 'Coverup2' }, options, false))
                break

            case 'Coverup2':
                if (awaiting[player.index] === 'Swap') {
                    if (type === 'action') {
                        updates[`players/${target1.index}/action`] = player.action
                        updates[`players/${player.index}/action`] = target1.action
                    } else {
                        updates[`players/${target1.index}/weapon`] = player.weapon
                        updates[`players/${player.index}/weapon`] = target1.weapon
                    }
                }
                isComplete = true
                break

            case 'Reveal':
                type = 'Character'
                isComplete = true
                break

            case 'Blackmail':
                if (options.type === 'Death') {
                    updates[`players/${target1.index}/weapon`] = target2.weapon
                    updates[`players/${target2.index}/weapon`] = target1.weapon
                    database.ref(`knowledge/${game.roomID}/${target1.id}/people/${target2.id}/weapon`).push(target1.weapon)
                    database.ref(`knowledge/${game.roomID}/${target2.id}/people/${target1.id}/weapon`).push(target2.weapon)
                } else if (options.type === 'Action') {
                    updates[`players/${target1.index}/action`] = target2.action
                    updates[`players/${target2.index}/action`] = target1.action
                }
                isComplete = true
                break

            case 'Secrets':
            case 'Bedridden':
                // TODO For now just have them select a card
                const drawIndex = options.cards.findIndex(c => options.card === c.id)
                const drawCard = options.cards[drawIndex]

                let discards = options.cards.splice(drawIndex, 1) // go back on top of the discard pile + the secrets card

                if (discardID) {
                    let replaceCardIndex = player.action.findIndex(h => h.id === action.cardID)
                    discards.concat(player.action.splice(replaceCardIndex, 1, drawCard)) // Add the Secrets card in the discard
                } else {
                    player.action.push(drawCard) // Was using a power
                }

                updates[`players/${player.index}/action`] = player.action
                updates[`game/discardDeck`] = game.discardDeck.concat(discards)

                // Either have them discard a card or perform the card action
                options.type = 'Action'
                options.cards = [ drawCard ]
                dispatch(setPendingAction(game, player, { name: 'Secrets2' }, options, false))
                isComplete = false
                break

            case 'Secrets2':
                // TODO: Player will need use or discard
                isComplete = true
                break

            case 'Doppelganger':
                updates[`players/${playerIndex}/isTargetable`] = false
                isComplete = true
                break

            case 'Soul Swap':
                updates[`players/${target1.index}/weapon`] = player.weapon
                updates[`players/${playerIndex}/weapon`] = target1.weapon
                updates[`players/${target1.index}/character`] = player.character
                updates[`players/${playerIndex}/character`] = target1.character
                break

            case 'Pass':
                isComplete = true
                break
        }

        // Update Player Knowledge
        if (type === 'Character') {
            database.ref(`knowledge/${game.roomID}/${player.id}/people/${target1.id}/character`).push(target1.character)
        } else if (type === 'Death') {
            database.ref(`knowledge/${game.roomID}/${player.id}/people/${target1.id}/weapon`).push(target1.weapon)
        } else if (type === 'Action') {
            database.ref(`knowledge/${game.roomID}/${player.id}/people/${target1.id}/action`).push(target1.action[0])
        }

        // Only continue if turn is over and game is not over
        if (!updates['gameover'] && isComplete) {
            updates['pending'] = false

            let cardToDiscard = options.discard
            if (cardToDiscard === undefined && action.cardID) cardToDiscard = action.cardID // Default to the used card

            let moreUpdates = dispatch(discardCard(game, playerIndex, cardToDiscard, false))
            updates = { ...updates, ...moreUpdates }
        }

        // Make all the updates
        console.warn('resolve', updates)
        return database.ref(`games/${game.roomID}`).update(updates).then(() => {
            if (isComplete && !updates.gameover) {
                database.ref(`games/${game.roomID}`).update({ nextTurn: true }) // Flag as the next turn
            }
        })
    }
}
