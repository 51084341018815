import { shuffle } from 'utils/arrays'

const cards = [
    {
        id: 'conspire',
        name: 'Conspire',
        type: 'action',
        icon: '/images/actions/Conspire.png',
        description: 'Offer to conspire with other players to murder someone.',
        inhand: 'Beyond the Veil',
        special: 'Unmask your murderer at the time of your death.',
        total: 3,
        interacts: ['Death']
    },
    {
        id: 'investigate',
        name: 'Investigate',
        type: 'action',
        icon: '/images/actions/Investigate.png',
        description: 'Look at another player’s weapon or action card. If 7 or more characters may be performed twice.',
        inhand: 'Return to the Scene',
        special: 'At any time you may use to look at the top card of the discard or draw pile.',
        total: 13,
        interacts: ['Discard']
    },
    {
        id: 'reveal',
        name: 'Reveal',
        type: 'action',
        icon: '/images/actions/Reveal.png',
        description: 'Look at another person’s secret identity.',
        inhand: 'Unmasking',
        special: 'If targeted by a reveal, you may also look at their identity.',
        total: 3,
        interacts: ['Reveal']
    },
    {
        id: 'coverup',
        name: 'Coverup',
        type: 'action',
        icon: '/images/actions/Coverup.png',
        description: 'Choose a player’s weapon or action card and secretly decide to trade it.',
        inhand: 'Above the Law',
        special: 'Use to prevent someone from investigating you.',
        total: 3,
        interacts: ['Investigate']
    },
    {
        id: 'blackmail',
        name: 'Blackmail',
        type: 'action',
        icon: '/images/actions/Blackmail.png',
        description: 'Pick one player and look at a Death or Action card. Make them swap with another player.',
        inhand: 'Force their Hand',
        special: 'Use to prevent a player from performing an initial action on you besides Conspire!.',
        total: 3,
        interacts: ['Investigate', 'Reveal', 'Coverup', 'Blackmail', 'Secrets']
    },
    {
        id: 'secrets',
        name: 'Secrets',
        type: 'action',
        icon: '/images/actions/Secrets.png',
        description: 'Look at top 4 of discard pile, and select one to use or keep.',
        inhand: 'Digging up Dirt',
        special: 'When in your hand at any time you may swap this card for the last card played.',
        total: 3,
        interacts: ['Discard']
    },
    {
        id: 'doppelganger',
        name: 'Doppelganger',
        type: 'action',
        icon: '/images/actions/Doppelganger.png',
        description: 'Prevent players from targeting you for a round.',
        inhand: 'Full Measure',
        special: 'If someone successfully conspires to murder you, the Doppelgänger dies in your place. Do not reveal anything.',
        total: 2,
        interacts: ['Conspire', 'Soul Swap']
    },
    {
        id: 'angel-of-death',
        name: 'Angel of Death',
        type: 'action',
        icon: '/images/actions/AngelOfDeath.png',
        description: 'Instant death to someone (no conspiracy required). Ignore effects of guessing wrong.',
        inhand: 'Backstab',
        special: 'Kills a player attempting to murder you. If they have a Doppelgänger, nothing occurs.',
        total: 1,
        interacts: ['Doppelganger']
    },
    {
        id: 'soul-swap',
        name: 'Soul Swap',
        type: 'action',
        icon: '/images/actions/SoulSwap.png',
        description: 'Swap characters and cards with another player.',
        inhand: 'The Dark Arts',
        special: 'If you are murdered, you take the place of your murderer. Swap identities and cards.',
        total: 1,
        interacts: ['Death']
    }
]

export const possibleResponses = {
    'Investigate': ['Coverup'],
    'Coverup': ['Blackmail'],
    'Blackmail': ['Blackmail'],
    'Reveal': ['Blackmail'],
    'Angel of Death': ['Doppelganger'],
    'Soul Swap': ['Doppelganger']
}

export const getDeck = () => {
    const deck = []
    let cid = 0
    cards.forEach(card => {
        for (let i = 0; i < card.total; i++) {
            let id = `action-card-${cid++}-${card.id}-${i}`
            deck.push({ ...card, id })
        }
    })

    return shuffle(deck)
}

export default cards
