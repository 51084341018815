import { combineReducers } from 'redux'
import sessionReducer from './session'
import { reducer as formReducer } from 'redux-form'

export const makeRootReducer = (asyncReducers) => {
    return combineReducers({
        session: sessionReducer,
        form: formReducer,
        ...asyncReducers
    })
}

export const injectReducer = (store, { key, reducer }) => {
    if (Object.hasOwnProperty.call(store.asyncReducers, key)) return

    store.asyncReducers[key] = reducer
    store.replaceReducer(makeRootReducer(store.asyncReducers))
}

export default makeRootReducer
