import React from 'react'
import Rules from './Rules'

import './Rules.scss'
import players from './assets/players.png'
import time from './assets/time.png'
import age from './assets/agist.png'

export const Page = () => <div className='rules-content page-content'>

    <nav><h2>Rules</h2></nav>

    <div className='content'>

        <div className='game-info'>
            <h4>
                <img src={players} alt='Players' />
                4 - 12<br/>People
            </h4>

            <h4>
                <img src={time} alt='Time' />
                30 - 60<br/>Minutes
            </h4>

            <h4>
                <img src={age} alt='Age' />
                12+<br/>No Babies
            </h4>
        </div>

        <p> <b className='text-primary'>Rasputin is dead!</b> His conspirators made sure of it
            using all manner and means possible:
            poisoned, beaten, bludgeoned, stabbed,
            shot, and drowned.</p>

        <p>
            However, Grigori was able to possess one of the Royal family.
            Now Rasputin and the Royal Family must kill all the conspirators before they put him to rest for good.
        </p>

        <p className='text-primary'>
            Play as a Royal, Conspirator, or be possessed by Rasputin and Conspire with others to get your <b>Revenge!</b>
        </p>

        <Rules />
    </div>

</div>

export default Page
