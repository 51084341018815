import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import md5 from 'md5'
import actions from 'models/actions'
import characters from 'models/characters'
import ActionCard from 'components/Cards/Action'
import './Rules.scss'

import {
    AGREE_TERMS,
    AGREE_HASH,
    setLocalStorage,
    getLocalStorage
} from 'store/localStorage'

export default class Page extends Component {

    state = { validated: null }

    componentWillMount() {
        let pw = getLocalStorage(AGREE_TERMS)
        if (pw && AGREE_HASH === md5(pw)) {
            this.setState({ validated: true })
        }
    }

    agreeToTerms = () => {
        let pw = prompt('Please enter your referral code', '')

        if (AGREE_HASH === md5(pw)) {
            setLocalStorage(AGREE_TERMS, pw)
            this.setState({ validated: true })
        } else {
            this.setState({ validated: false })
        }
    }

    render () {
        const { validated } = this.state

        return <div className='rules-container'>

            { validated && <Fragment>
                <hr/>

                <h3 className='text-center'>What's Inside</h3>
                    <ul className='list cols'>
                        <li>12 Player Mats</li>
                        <li>12 Secrecy Envelopes</li>
                        <li>12 Conspire / Death Tokens</li>
                        <li>14 Death Cards</li>
                        <li>12 Character Cards</li>
                        <li>32 Action Cards</li>
                        <li>48 Gems</li>
                        <li>Moika Palace</li>
                    </ul>

                <hr />

                <h3 className='text-center'>Setup</h3>

                <p> At the beginning of play, everyone is dealt a character card held in a secrecy envelope.</p>

                <ol>
                    <li>Set aside a number of weapons equal to the number of players.</li>
                    <li>Shuffle and set one weapon in a secrecy envelope and place it at Moika Palace.</li>
                    <li>Then add the Rasputin card, shuffle and give each person a card.</li>
                    <li>Everyone is then dealt 1 action card.</li>
                    <li>Give each player a Conspire token.</li>
                </ol>

                <p> <span className='text-primary'>7+ Players </span>
                    When playing with large groups. Everyone will close their eyes.
                    Whoever is Prince Felix will open their eyes,
                    and all the other conspirators will stick out their thumbs.</p>

                <p> <span className='text-primary'>10+ Players </span>
                    Instead of selecting 1 weapon for Moika Palace, use 2 and add the Bloodlust card.</p>

                <hr />

                <h3 className='text-center'>Gameplay</h3>

                <p> After the game has been set up,
                    play goes clockwise starting with the most commonly targeted player.
                    A player can complete 1 action per turn (2 actions if the group is 7 or more).
                    The players are then allowed to discuss what just happened and share information if they want.
                    It is often wise to not reveal much information.</p>

                <h4>Player Actions</h4>
                <p> What action is taken depends on the card they draw (in secret).
                    Actions can be done in 1 of 3 ways:</p>

                <ol>
                    <li>Using a card in their hand</li>
                    <li>If their character is allowed to perform a specific action
                        (you can only use a power once per turn)</li>
                    <li>Use their one-time-use conspire token.</li>
                </ol>

                <p> When a player performs an action he must either discard the card they used,
                    or a card in place of that action.
                    Regardless of their action a player must discard
                    till they have one action card in left their hand.</p>

                <p> The last card in a players hand can then be used in defence of another players action.</p>
                <p> When a card is used in defence or as a reaction on someone elses turn, you must discard it and draw a new card. </p>

                <div className='action-cards'>
                    {actions.map(action => <ActionCard action={action} />)}
                </div>

                <h4>Conspiracies</h4>
                <p> A player will initiate this and call a vote, either from using a token, a power, or an action card.
                    Everyone will raise their hands to decide if the person is assassinated
                    (thumbs up or thumbs down). If there is at least one other conspirator then the execution will occur.
                    If no one else chooses to go forward with the plan, the action will fail and nothing happens.
                    Tokens are still used in the event of a failed conspiracy.</p>

                <p><span className='text-primary'>Special cases </span>
                    When there are only 2 players left, no vote is required.
                    When there are 7 or more characters,
                    2 others must vote to conspire.</p>

                <p>When a conspiracy occurs, one of four things may occur.</p>

                <ul className='list'>
                    <li><span className='text-primary'>Reversal of Fortune: </span>
                        If the player that conspired to murder someone uses the weapon that the potential victim has,
                        the conspirator is murdered instead.</li>
                    <li><span className='text-primary'>Assassination: </span>
                        The person is not Rasputin and is murdered with a weapon they do not have.
                        Then that player is assassinated.</li>
                    <li><span className='text-primary'>Exorcism: </span>
                        If Rasputin was murdered with the correct murder weapon the conspirators win.</li>
                    <li><span className='text-primary'>Repossession: </span>
                        Rasputin is murdered, but not with the correct murder weapon.</li>
                </ul>

                <p> <span className='text-primary'>If Rasputin is murdered</span>,
                    that player looks to see if they were murdered by the correct weapon.
                    The player cannot state what the weapon is,
                    and can only confirm whether or not the correct weapon was used.</p>

                <p> <span className='text-primary'>If Rasputin was murdered with the incorrect weapon</span>,
                    the person that murdered him dies if they were a conspirator.
                    Then everyone else must close their eyes as Rasputin chooses another victim to possess.
                    The old Rasputin trades their Rasputin card for the weapon of the new Rasputin.
                    When a player has been assassinated, they have different actions they may complete.</p>

                <h3 className='text-center'>The Dead</h3>
                <p>Death is not the end. Players may choose to continue their roles in the game, but now have a different set of actions.</p>

                <h4>When a player dies the following occurs</h4>
                <ol>
                    <li>The players must reveal their identity, their action cards are discarded.</li>
                    <li>Their death card remains hidden (unless they were Rasputin).</li>
                </ol>

                <h4>A dead player may perform 2 of the following on their turn</h4>
                <ol>
                    <li>Investigate a player.</li>
                    <li>Blackmail two players.</li>
                    <li>Whisper a secret in someone elses ear.</li>
                </ol>

                <hr />

                <h3 className='text-center'>How to Win</h3>

                <p> <span className='text-primary'>Conspiracy!</span> Has other methods of play.
                    Conspirators win by staying alive and killing Rasputin with the correct murder weapon.</p>

                <p> The Royals win by eliminating all the conspirators, and keeping Rasputin alive.</p>

                <h3 className='text-center'>About the People</h3>

                <div className='characters'>
                    {characters.map(c => <Link to={`/character/${c.slug}`} className='character'>
                        <img src={c.card} alt='' />
                        <h5>{c.name}</h5>
                    </Link>)}
                </div>

                <hr/>

                <h3 className='text-center'>Game Sizes</h3>

                <h5 className='text-primary'>7+ Players</h5>
                <ul className='list'>
                    <li>Users draw up to till they have 3 cards, and perform 2 actions, discarding to 1 card.</li>
                    <li>Once a character dies (not Rasputin) their weapon is revealed.</li>
                    <li>When this many people alive, conspiracies require at least 2 accomplices to happen.</li>
                </ul>

                <h5 className='text-primary'>10+ Players</h5>
                <ul className='list'>
                    <li>2 Death Weapon Cards are placed inside Moika Palace (either card will kill Rasputin)</li>
                    <li>Bloodlust Card is now dealt into the weapons deck.</li>
                    <li>The Rasputin card is then added and the death cards are handed out as normal.</li>
                </ul>

                <h5>
                    <span className='text-primary'>The Bloodlust Card</span> is added for larger groups.
                </h5>
                <ul className='list'>
                    <li>A player inflicted with Bloodlust votes yes on all conspiracies.</li>
                    <li>Shuffle it in the weapons deck before 2 cards are placed in Moika.
                        This means it is possible there is only one weapon and no betrayal card in play.</li>
                </ul>


                <h3 className='text-center'>Scoring ~Optional~</h3>

                <h4>Conspirators Win</h4>
                <p> If Conspirators win,
                    any alive conspirators gain points equal to the number of alive Royals..</p>

                <h4>Royals Win</h4>
                <p> If the Royals and Rasputin win,
                    they get points equal to Royals left alive.</p>

                <hr />

                <Link to='/play' className='btn btn-primary' >
                    Demo App
                </Link>

            </Fragment> }

            {!validated && <p className='text-center'>
                <a href='https://www.facebook.com/groups/1320104034797511' target='_blank' rel='noreferrer' className='btn btn-primary' >
                    Signup for Playtesting
                </a>
                <button className='btn btn-secondary' type='button' onClick={this.agreeToTerms}>
                    Use referral code
                </button>
            </p>}

            {validated === false && <h4>Ooops... Invalid Referral Code</h4>}
        </div>
    }

}
