import React from 'react'
import './Action.scss'

const ActionCard = ({ action, isFlipped, isSmall = false, onClick }) => <div
    onClick={onClick}
    className={`game-card game-action-card ${isFlipped ? 'flipped' : ''} ${isSmall ? 'small' : 'normal'}`}
>
    <div className='card-sides'>
        <div className='face'>
            <div className='face-content'>
                <img src={action.icon} className='action' height='56' alt='' />
                <h4>{action.name}</h4>
                <p>{action.description}</p>

                <hr/>

                <div className='while'>While In-hand</div>
                <h5 className='text-secondary'>{action.inhand}</h5>
                <p>{action.special}</p>
            </div>
        </div>
    </div>
</div>

export default ActionCard
