import { shuffle } from 'utils/arrays'

const cards = [
    {
        id: 1,
        name: 'Revolver',
        type: 'death',
        image: '/images/weapons/Revolver.png'
    },
    {
        id: 2,
        name: 'Rope',
        type: 'death',
        image: '/images/weapons/Rope.png'
    },
    {
        id: 3,
        name: 'Dagger',
        type: 'death',
        image: '/images/weapons/Dagger.png'
    },
    {
        id: 4,
        name: 'Poison',
        type: 'death',
        image: '/images/weapons/Poison.png'
    },
    {
        id: 5,
        name: 'Razor',
        type: 'death',
        image: '/images/weapons/Razor.png'
    },
    {
        id: 6,
        name: 'Statue',
        type: 'death',
        image: '/images/weapons/Statue.png'
    },
    {
        id: 7,
        name: 'Cane',
        type: 'death',
        image: '/images/weapons/Cane.png'
    },
    {
        id: 8,
        name: 'Rifle',
        type: 'death',
        image: '/images/weapons/Rifle.png'
    },
    {
        id: 9,
        name: 'Scissors',
        type: 'death',
        image: '/images/weapons/Scissors.png'
    },
    {
        id: 10,
        name: 'Wire',
        type: 'death',
        image: '/images/weapons/Wire.png'
    },
    {
        id: 11,
        name: 'Saber',
        type: 'death',
        image: '/images/weapons/Saber.png'
    },
    {
        id: 12,
        name: 'Icepick',
        type: 'death',
        image: '/images/weapons/Icepick.png'
    },
    {
        id: 13,
        name: 'Club',
        type: 'death',
        image: '/images/weapons/Club.png'
    },
    {
        id: 14,
        name: 'Axe',
        type: 'death',
        image: '/images/weapons/Axe.png'
    },
    {
        id: 15,
        name: 'Molotov',
        type: 'death',
        image: '/images/weapons/Molotov.png'
    }
]

export const rasputin = {
    id: -1,
    name: 'Rasputin',
    type: 'death',
    image: '/images/weapons/Rasputin.png'
}

export const bloodlust = {
    id: -2,
    name: 'Timebomb',
    type: 'death',
    image: '/images/weapons/Bloodlust.png'
}

export const getDeck = (players) => {
    return shuffle(cards.slice(0, players))
}

export default cards
