import React from 'react'
import { Link } from 'react-router-dom'
import characters from 'models/characters'

import Moika from './MoikaPalace.png'
import './Page.scss'

export const Page = () => <div className='page-content page-lore'>

    <nav><h2>Lore</h2></nav>

    <div className='content'>

        <h3>The Assassination of Grigori Rasputin</h3>

        <section className='hero-digest'>
            <div className='meta'>
                <h4>Grigori Rasputin</h4>
                <p> Grigori Rasputin was born into a peasant family in Siberia, Russia, around 1869.
                    After failing to become a monk, Rasputin became a wanderer and eventually entered the
                    court of <Link to='/character/tsar'>Tzar Nicholas II</Link>
                    and <Link to='/character/empress'>Empress Alexandra Feodorovna</Link> because
                    of his alleged healing abilities, of utmost importance to the young
                    <Link to='/character/heir'>Heir Alexei</Link>.
                </p>
            </div>

            <img src='images/characters/Rasputin.png' height={300} alt='Rasputin' />
        </section>

        <section className='hero-digest'>
            <img src={Moika} height={300} alt='Moika Palace Illustration' />

            <div className='meta'>
                <h4>Murder at Moika Palace</h4>
                <p> Having decided that Rasputin's influence over the Tsarina had made him a threat to the empire,
                    a group of nobles led by <Link to='/character/prince-felix'>Prince Felix Yusupov</Link>,
                    the <Link to='/character/grand-duke'>Grand Duke Dmitri Pavlovich</Link>,
                    and the right-wing politician <Link to='/character/politician'>Vladimir Purishkevich</Link> concocted
                    a plan to kill Rasputin in December 1916, apparently by luring Rasputin to the Yusupovs' Moika Palace.</p>
            </div>
        </section>


        <h3>Cast of Characters</h3>

        <ol className='character-cast'>
            {characters.map((character, i) => <li className={character.team} key={`character${i}`}>

                <Link to={`/character/${character.slug}`}>
                    <img src={character.card} width='200' className='image' alt='' />
                    <h4>{character.name}</h4>
                </Link>

            </li>)}
        </ol>

    </div>

</div>

export default Page
