import { createSelector } from 'reselect'
import weapons from "../models/weapons";

// ------------------------------------
// Constants
// ------------------------------------
export const SESSION_CHANGE = 'SESSION_CHANGE'
export const USER_CHANGE = 'USER_CHANGE'
export const ROOM_CHANGE = 'ROOM_CHANGE'
export const CHAT_CHANGE = 'CHAT_CHANGE'
export const GAME_CHANGE = 'GAME_CHANGE'
export const NOTE_CHANGE = 'NOTE_CHANGE'

// ------------------------------------
// Actions
// ------------------------------------
export function sessionChange (session) {
    return {
        type    : SESSION_CHANGE,
        payload : session
    }
}

export function userChange (user) {
    return {
        type    : USER_CHANGE,
        payload : user
    }
}

export function roomChange (room) {
    return {
        type: ROOM_CHANGE,
        payload: room
    }
}

export function chatChange (log) {
    return {
        type: CHAT_CHANGE,
        payload: log
    }
}

export function gameChange (game) {
    return {
        type: GAME_CHANGE,
        payload: game
    }
}

export function notesChange (notes) {
    return {
        type: NOTE_CHANGE,
        payload: notes
    }
}

// ------------------------------------
// Specialized Action Creator
// ------------------------------------
export const updateSession = ({ dispatch }) => {
    return (user) => dispatch(userChange(user))
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    session: null,
    user: null,
    room: null,
    chat: null,
    game: null,
    notes: null,
}

export default function locationReducer (state = initialState, action) {
    switch (action.type) {
        case SESSION_CHANGE:
            return { ...state, session: action.payload }

        case USER_CHANGE:
            return { ...state, user: action.payload }

        case ROOM_CHANGE:
            return { ...state, room: action.payload }

        case CHAT_CHANGE:
            return { ...state, chat: action.payload }

        case GAME_CHANGE:
            return { ...state, game: action.payload }

        case NOTE_CHANGE:
            return { ...state, notes: action.payload }

        default: return state
    }
}

export const getSession = (state, props) => state.session

export const getUserID = createSelector(
    getSession,
    sesh => sesh.session.uid
)

export const getUser = createSelector(
    getSession,
    session => session && session.user
)

export const getRoom = createSelector(
    getSession,
    session => session && session.room
)

export const getChat = createSelector(
    getSession,
    session => session && session.chat
)

export const getGame = createSelector(
    getSession,
    session => session && session.game
)

export const getNotes = createSelector(
    getSession,
    session => session && session.notes
)

export const yourPlayer = createSelector(
    getUser,
    getRoom,
    getGame,
    getUserID,
    (user, room, game, id) => {
        if (id && game) {
            const { players = [] } = game
            return players.find((player) => (player.id === id)) || (room && room.people && room.people[id])
        }

        return null
    }
)

export const hostID = createSelector(
    getRoom,
    room => room.owner
)

export const activePlayer = createSelector(
    getGame,
    game => game && game.players[game.activePlayer]
)
