import React, { useEffect } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import Logo from 'routes/Home/assets/Logo.png'
import RubySpritesLogo from './RubySpriteLogo.png'
import './PageLayout.scss'

function handleUpdate (location) {
    const { action } = location
    window.scrollTo(0, 0)
}

export const PageLayout = ({ children, history, location, ...props }) => {

    useEffect(() => {
        history.listen(handleUpdate)
    }, [history])

    const year = (new Date()).getFullYear()
    const { pathname } = location
    const hideNav = pathname === '/play'

    return <div className='page-layout'>

        {!hideNav && <header className='header'>
            <div>
                <NavLink className='logo' to='/'>
                    <img src={Logo} className='logo' alt='About Conspiracy' />
                </NavLink>
                <NavLink to='/lore' activeClassName='header-active'>Lore</NavLink>
                <NavLink to='/rules' activeClassName='header-active'>Rules</NavLink>
                <NavLink to='/notes' activeClassName='header-active'>Notes</NavLink>
            </div>
        </header>}

        <main className='page-content'>
            {children}
        </main>

        {!hideNav && <footer>
            <a href='http://RubySprite.Games'>
                <img src={RubySpritesLogo} height='100' alt='Ruby Sprite Games' />
            </a>
            <p> {`© ${year}`}</p>
        </footer>}
    </div>
}

export default withRouter(PageLayout)
