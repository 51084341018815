import React, { Component, Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'
import {
    NOTE_STORAGE_KEY,
    setLocalStorage,
    getLocalStorage
} from 'store/localStorage'

import ActionCard from 'components/Cards/Action'

import characters from 'models/characters'
import weapons from 'models/weapons'
import actions from 'models/actions'
import './Notes.scss'

export class Notes extends Component {

    state = { tab: 'characters', isRasputin: false }

    componentWillMount() {
        const { initialValues = {} } = this.props
        this.setState({ isRasputin: (initialValues && initialValues.isRasputin) || false })
    }

    toggleRasputin = (ev) => {
        this.setState({ isRasputin: ev.target.checked })
    }

    get characters() {
        const { player, players } = this.props
        return characters.slice(0, players).sort((p1, p2) => {

            if (p1.team === p2.team) {
                return p1.id - p2.id
            }

            if (p1.team === player.team) {
                return -1
            }

            return 1
        })
    }

    playerAllegiance(character) {
        const { player } = this.props
        const { isRasputin } = this.state

        if (player.name === character.name) {
            return <i className="fas fa-star" />
        }

        if (player.team === 'royal' || isRasputin) {
            return character.team === 'royal' ? <i className="fas fa-handshake" /> : <i className='fas fa-crosshairs' />
        }

        return character.team === player.team ? <i className="fas fa-handshake" /> : <i className='fas fa-crosshairs' />
    }

    render () {
        const { player, players, section = 'characters' } = this.props
        const { tab, isRasputin } = this.state


        return <Fragment>

            <nav>
                <NavLink activeClassName='active' to='/notes/characters'>
                    <i className='fas fa-user-secret' />
                    Players
                </NavLink>
                <NavLink activeClassName='active' to='/notes/death'>
                    <i className='fas fa-skull' />
                    Death Cards
                </NavLink>
                <NavLink activeClassName='active' to='/notes/actions'>
                    <i className='fas fa-search' />
                    Actions
                </NavLink>
            </nav>

            <div className='content'>
                <section className={section === 'characters' ? 'visible' : 'hide'}>
                    <h4> Record your enemies and allies.</h4>

                    <label className='areYouRasputin'>
                        <img src='/images/characters/Rasputin.png' className='rasputin' alt='Rasputin' />
                        {' Are you possessed by Rasputin? '}
                        <Field name='isRasputin' component='input' type='checkbox' onChange={this.toggleRasputin} /> Yes
                    </label>

                    {!isRasputin && player.team === 'conspirator' && <h3 className='text-secondary'>Find and Kill Rasputin!</h3>}
                    {!isRasputin && player.team === 'royal' && <h3 className='text-secondary'>Find and Protect Rasputin; Kill The Conspirators!</h3>}
                    {isRasputin && <h3 className='text-secondary'>Find and Kill The Conspirators!</h3>}

                    <ol className='character-chart'>
                        {this.characters.map((character, i) => <li className={character.team} key={`character${i}`}>

                            <div className='index'>
                                { this.playerAllegiance(character) }
                            </div>

                            <img src={character.card} width='100' className='image' alt='' />

                            <div className='faction'>
                                {character.team === 'conspirator' && <img src='/images/characters/dagger.png' alt='Conspirator' />}
                                {character.team === 'royal' && <img src='/images/characters/crown.png' alt='Royal' />}
                                {character.team === 'none' && <i className='fas fa-star' />}
                            </div>

                            <h4>{character.name}
                                <span className='subtitle'>Ability: {character.ability}</span>
                            </h4>

                            <label className='who'>
                                <Field component='input' name={`character-${i}`} type='text' placeholder='Who are they?' />
                            </label>

                        </li>)}
                    </ol>
                </section>

                <section className={section === 'death' ? 'visible' : 'hide'}>
                    <ul className='bullet-list'>
                        <li>
                            <i className='fas fa-check' />
                            <p>Mark off the weapons you've seen to find the real murder weapon.</p>
                        </li>
                        <li>
                            <i className='fas fa-exclamation-circle' />
                            <p>If you murder someone with the weapon they are holding, it will backfire.</p>
                        </li>
                    </ul>

                    <ul className='weapons-list'>
                        <li className='weapon rasputin'>
                            <img src={140} src='/images/weapons/Rasputin.png' alt='Rasputin' />
                            {!isRasputin && <Field name='rasputin' component='input' type='text' placeholder='Who is Rasputin?' className='w-100' />}
                            {isRasputin && 'You are possessed!'}
                        </li>

                        {players > 9 && <li className='weapon'>
                            <img src={140} src='/images/weapons/Bloodlust.png' alt='Bloodlust' />
                            <Field name='betrayer' component='input' type='text' placeholder='Has Bloodlust?' className='w-100' />
                        </li>}

                        <hr />

                        {weapons.slice(0, players).map((weapon, i) => <li className='weapon' key={weapon.name}>
                            <label>
                                <Field component='input' name={weapon.name} type='checkbox' />
                                <img src={weapon.image} width={140} alt='' />
                                <h4>{ ` ${weapon.name} ` }</h4>
                            </label>
                            <Field
                                component='input'
                                type='text'
                                name={`${weapon.name}-owner`}
                                className='owner'
                                placeholder='Held by?'
                            />
                        </li>
                        )}
                    </ul>
                </section>

                <section className={section === 'actions' ? 'visible' : 'hide'}>
                    <h4>Turn Order</h4>
                    {players > 6
                        ? <ol className='turn-order'>
                            <li>Draw till you have 3 cards in hand.</li>
                            <li>Perform and resolve 1 action, discarding the relevant card.</li>
                            <li>If a power or conspire token is used, also discard a card of your choice.</li>
                            <li>Resolve actions till you have 1 Card in your hand.</li>
                            <li>The card in your hand can be used on someone else turn, but is then discarded.</li>
                        </ol>
                        : <ol className='turn-order'>
                            <li>Draw till you have 2 cards in hand.</li>
                            <li>Perform and resolve 1 action, discarding the relevant card.</li>
                            <li>If a power or conspire token is used, also discard a card.</li>
                            <li>The card in your hand may be used in reaction to someone targeting you.
                                Draw a new card after revealing and resolving.</li>
                        </ol>
                    }

                    <div className='actions'>
                        {actions.map(action => <ActionCard action={action} />)}
                    </div>
                </section>

            </div>

        </Fragment>
    }
}

export default reduxForm({
    form: 'GameNotes',
    onChange: (values) => setLocalStorage(NOTE_STORAGE_KEY, values)
})(Notes)
