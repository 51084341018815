import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import Notes from 'components/Notes/Notes'
import characters from 'models/characters'
import {
    PLAYER_ID,
    PLAYER_COUNT,
    NOTE_STORAGE_KEY,
    setLocalStorage,
    getLocalStorage
} from 'store/localStorage'

import './Page.scss'

class Page extends Component {

    state = { players: false, player: false }

    componentWillMount() {
        const players = getLocalStorage(PLAYER_COUNT)
        const player_id = getLocalStorage(PLAYER_ID)
        if (players) this.setGameSize(players)
        if (player_id) this.setPlayer(player_id)
    }

    setGameSize = (players) => {
        setLocalStorage(PLAYER_COUNT, players)
        this.setState({ players })
    }

    setPlayer = (id) => {
        setLocalStorage(PLAYER_ID, id)
        this.setState({ player: characters.find(c => c.id === +id) })
    }

    selectPlayer = (player) => {
        this.setState({ player })
    }

    clearForm = () => {
        if (window.confirm("Start a new game?")) {
            this.setGameSize(false)
            this.setPlayer(false)
            setLocalStorage(NOTE_STORAGE_KEY, {})
            setLocalStorage(PLAYER_ID, false)
        }
    }

    render () {
        const { match } = this.props
        const { players, player } = this.state
        const minPlayers = 4
        const initialValues = getLocalStorage(NOTE_STORAGE_KEY)
        const { params } = match

        return <div className='notes-content page-content'>

            {!players && <Fragment>
                <nav><h3>Let's get started</h3></nav>

                <section className='group-select'>
                    <i className='fas fa-users' />
                    <label>How many people are playing? </label> <br />
                    <select name='players' onChange={(ev) => this.setGameSize(ev.target.value)}>
                        <option value=''>4 to 15 Players</option>
                        {Array(12).fill(1).map((el, i) => <option value={i + minPlayers} key={i}>
                            {i + minPlayers} Players
                        </option>)}
                    </select>
                </section>

            </Fragment>}

            {!!players && !player && <div className='character-select'>
                <nav><h3>Let's get started</h3></nav>
                <i className='fas fa-user-secret' />
                <h3>Who Are You?</h3>
                {characters.slice(0, players).map(c => <div className='character' key={c.name}>
                    <button
                        type='button'
                        onClick={() => { this.setPlayer(c.id) }}
                        className='character-avatar'
                    >
                        <img src={c.avatar} alt='' />
                    </button>
                    <h4>{c.name}</h4>
                </div>)}
            </div>}

            {!!players && !!player && <Notes
                initialValues={initialValues}
                player={player}
                players={players}
                section={params.section}
            />}

            {!!players && <button type='button' className='btn btn-secondary' onClick={this.clearForm}>
                New Game
            </button>}

        </div>
    }
}

export default withRouter(Page)
