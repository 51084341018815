import React from 'react'
import { Link } from 'react-router-dom'
import './Home.scss'

import BoxArt from '../assets/BoxArt-Conspiracy.png'
import players from "../../Rules/components/assets/players.png";
import time from "../../Rules/components/assets/time.png";
import age from "../../Rules/components/assets/agist.png";

const HomePage = () => <div className='home-content'>

    <section className='hero-digest'>
        <img src={BoxArt} width='600' alt='Boxart' />
        <div className='meta'>
            <h2>Conspiracy</h2>
            <h3>A social deduction board game.</h3>

            <div className='badges'>
                <div className="badge">Secret Identities</div>
                <div className="badge">Factions</div>
                <div className="badge">Deduction</div>
                <div className="badge">Deception</div>
            </div>
            <p> Conspiracy puts the Royal Romanovs against Prince Felix's Conspirators.
                With the aid of the ghost of Rasputin, the Royals are in a race to uncover the enemies that led to Rasputin's demise
                and bring them to an end. The Conspirators must finish the job and end Rasputin once and for all.</p>

            <div className='game-info'>
                <h4>
                    <img src={players} alt='Players' />
                    <br/>
                    4 - 12<br/>People
                </h4>

                <h4>
                    <img src={time} alt='Time' />
                    <br/>
                    30 - 60<br/>Minutes
                </h4>

                <h4>
                    <img src={age} alt='Age' />
                    <br/>
                    12+<br/>No Babies
                </h4>
            </div>

        </div>

        <div>
            <Link className='button' to='/rules'>Rulebook</Link>
            <Link className='button' to='/notes'>Game Companion</Link>
            <p>
                <a href='https://www.facebook.com/groups/1320104034797511/' rel='noreferrer' target='_blank'>
                    Sign up to be Playtester!
                </a>
            </p>
        </div>
    </section>
</div>

export default HomePage
