import App from 'components/App'
import createStore from 'store/createStore'
import './App.css';
import 'styles/main.scss'

const store = createStore()

function Main() {
  return (
    <App store={store} />
  );
}

export default Main;
